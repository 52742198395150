import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { IBookChapter, IBookData } from 'Components/books/types';
import { AUTH_USER_TOKEN_KEY, CONSULT_URL} from 'Constants';
import s from './BookGenerator.module.scss';
import Input from 'Components/books/Input/Input';
import TextArea from 'Components/books/TextArea/TextArea';
import Select from 'Components/books/Select/Select';
import Button from 'Components/Common/Button/Button';
import { graphQlCall } from 'graphql/utils';
import queries from 'graphql/queries';
import { Socket } from 'socket.io-client';
import Slider from 'Components/books/Slider/Slider';
import BookInfoBlock from 'Components/books/BookInfoBlock/BookInfoBlock';
import { fetchSectionsMenu } from 'rx/actions/rxFetchSectionsMenu';
import {
  rxNewChapterIndex,
  rxChapterIndexForDelete,
  rxSelectBookImg,
  rxBadContentAlert,
} from 'rx/rxState';
import { getBySocket, createSocket } from 'utils/socket';
import { getToken } from 'utils/api';
import { initiateBadWordsFilter } from 'Utils';
import InappropriateAIContentPopup from 'Components/Popups/InappropriateAIContentPopup';
import Logo from 'Components/Logo/Logo';
import { ReactComponent as ArrowLeft } from '../Assets/arrow-left-back.svg';
import clsx from 'clsx';
import LoadingSpinner from 'Components/Common/LoadingSpinner/LoadingSpinner';

const badWordFilter = initiateBadWordsFilter();

const aviableTones = [
  'Professional',
  'Conversational',
  'Relaxed',
  'Technical',
  'Custom',
  '<split>',
  'Apathetic',
  'Australian English',
  'Bittersweet',
  'British English',
  'Casual',
  'Cynical',
  'Dark',
  'Dispassionate',
  'Enthusiastic',
  'Formal',
  'Gothic',
  'Hopeful',
  'Humorous',
  'Ironic',
  'Melancholic',
  'Mysterious',
  'Nostalgic',
  'Optimistic',
  'Provocative',
  'Regretful',
  'Sarcastic',
  'Satirical',
  'Semi-Professional',
  'Sentimental',
  'Serious',
  'Sincere',
  'Uplifting',
  'Whimsical',
  'Witty',
];

function extractChapterTitle(string: string) {
  const chapterRegex = /^(?:\w+\s+)*Chapter\s+(\d+):?\s+(.+)/;
  const match = string.match(chapterRegex);

  if (match) {
    return match[2];
  } else {
    return string;
  }
}

const BookGenerator = () => {
  let history = useHistory();

  const [customTone, setCustomTone] = useState('');
  const [customToneEnabled, setCustomToneEnabled] = useState(false);
  const [bookTitle, setBookTitle] = useState('');
  const [bookDescription, setBookDescription] = useState('');
  const [tone, setTone] = useState<string | null>(null);
  const [bookData, setBookData] = useState<IBookData | null>(null);

  const [titleHasError, setTitleHasError] = useState(false);
  const [toneHasError, setToneHasError] = useState(false);
  const [descriptionHasError, setDescriptionHasError] = useState(false);
  const [customToneHasError, setCustomToneHasError] = useState(false);

  const [bookAudience, setBookAudience] = useState('');
  const [bookStructure, setBookStructure] = useState('');
  const [bookAudienceHasError, setBookAudienceHasError] = useState(false);
  const [bookStructureHasError, setBookStructureHasError] = useState(false);
  const [step, setStep] = useState<number>(1);

  const [loading, setLoading] = useState(false);
  const [loadingChapter, setLoadingChapter] = useState(false);
  const [loadingBookImg, setLoadingBookImg] = useState(false);
  const [loadingAddChapter, setLoadingAddChapter] = useState(false);

  const [bookImg, setBookImg] = useState<string[]>([]);
  const [selectBookImg, setSelectBookImg] = useState<string | null>();

  const chapterPositionForGql = useRef<number[]>([]);
  const socket = useRef<Socket | null>(null);

  const [socketStatus, setSocketStatus] = useState('closed');
  const token = getToken();

  useEffect(() => {
    if (!selectBookImg) {
      setSelectBookImg(bookImg[0]);
    }
  }, [bookImg]);

  useEffect(() => {
    if (selectBookImg) {
      rxSelectBookImg.next(selectBookImg);
    }
  }, [selectBookImg]);

  useEffect(() => {
    if (!getToken()) {
      const url = `/console/login`;
      window.open(url, "_self");
    }
    if (socketStatus === 'closed') {
      socket.current = createSocket();
      socket.current.on('connect', () => {
        console.log('connect');

        setSocketStatus('connected');
      });
    }
  }, []);

  useEffect(() => {
    if (bookData && socket.current) {
      socket.current.on('open-ai-image-generated', (data) => {
        if (data.imageUrls.length) {
          setLoadingBookImg(false);
          setBookImg(data.imageUrls);
        }
      });
    }
  }, [bookData]);

  const fetchChapters = async () => {
    if (!checkFields()) {
      return;
    }
    setLoading(true);

    let bookTone = tone;
    if(customToneEnabled) {
      bookTone = customTone;
    }

    const createBookData: IBookData = await graphQlCall({
      queryTemplateObject: queries.CREATE_BOOK_MUTATION,
      headerType: 'USER-AUTH',
      values: {
        tone: bookTone,
        description: bookDescription,
        title: bookTitle,
      },
    });
    setBookData(createBookData);
    setLoading(false);
  };

  const generateBookTableOfContext = async (bookId: string) => {
    if (!socket.current) {
      return;
    }
    const data: any = await getBySocket({
      emitEventName: 'ai-book-table-content',
      resultEventName: 'response-book-table-content',
      payload: {
        bookId,
      },
      socket: socket.current,
    });
    const newBookData: IBookData = { ...bookData } as IBookData;
    const chapters = data.chapters.map((chapter: string, index: number) => {
      chapterPositionForGql.current.push(index);
      return {
        title: chapter,
        text: '',
        comments: [],
      };
    });
    newBookData.chapters = chapters as IBookChapter[];
    setBookData(newBookData);
    setLoadingChapter(false);
  };

  const handleToneInput = (value: string) => {
    setToneHasError(false);
    setTone(value);
    setCustomToneEnabled( value === 'Custom');
  };

  const handleCustomToneInput = (value: string) => {
    setCustomToneHasError(false);
    setCustomTone(value);
  }

  const handleNameInput = (value: string) => {
    setTitleHasError(false);
    const words = value.split(" ");
    const capitalizedWords = words.map((word) => {
      if (word.length > 0) {
        return word[0].toUpperCase() + word.slice(1);
      }
      return word;
    });
    const capitalizedValue = capitalizedWords.join(" "); 
    setBookTitle(capitalizedValue);
  };

  const handleDescriptionInput = (value: string) => {
    setDescriptionHasError(false);
    setBookDescription(value);
  };

  const checkFields = () => {
    let valid = true;
    if (bookDescription.trim().length === 0) {
      setDescriptionHasError(true);
      valid = false;
    }
    if (tone === null || tone.trim().length === 0) {
      setToneHasError(true);
      valid = false;
    }

    if (bookTitle.trim().length === 0) {
      setTitleHasError(true);
      valid = false;
    }

    if (customToneEnabled && customTone.trim().length === 0){
      setCustomToneHasError(true);
    }
    return valid;
  };

  const handleNext = async () => {
    if (step === 1) {
      // if (badWordFilter.isProfane(bookTitle) || badWordFilter.isProfane(bookDescription)) {
      //   rxBadContentAlert.next(true);
      //   return;
      // }
      await fetchChapters();
      if (!checkFields()) {
        return;
      }
      setStep(2);
    } else if (step === 2) {
      // if (badWordFilter.isProfane(bookAudience) || badWordFilter.isProfane(bookStructure)) {
      //   rxBadContentAlert.next(true);
      //   return;
      // }
      if (bookAudience.trim() || bookStructure.trim()) {
        await addAudienceAndStructure();
      } else {
        skipAddAudienceAndStructure();
      }
      setStep(3);
    }
  };

  const addAudienceAndStructure = async () => {
    setLoading(true);
    const bookId = bookData?._id;
    if (!bookId || !socket.current) {
      return;
    }
    const updateBookData: IBookData = await graphQlCall({
      queryTemplateObject: queries.UPDATE_BOOK_MUTATION,
      headerType: 'USER-AUTH',
      values: {
        id: bookId,
        audience: bookAudience,
        structure: bookStructure,
      },
    });
    setBookData(updateBookData);

    setLoadingChapter(true);
    setLoadingBookImg(true);
    await generateBookTableOfContext(bookId);

    generateBookCoverByAI(bookTitle);
    setLoading(false);
  };

  const generateBookCoverByAI = (text: string) => {
    // const hasBadWords = badWordFilter.isProfane(text);
    // if (hasBadWords) {
    //   rxBadContentAlert.next(true);
    //   return;
    // }

    socket.current?.emit('generate-openai-image', {
      text: text,
      ratio: '6:4',
      imageCount: 4,
      bookId: bookData?._id
    })
  }

  const skipAddAudienceAndStructure = async () => {
    const bookId = bookData?._id;
    if (bookId) {
      setStep(3);
      setLoadingChapter(true);
      setLoadingBookImg(true);
      await generateBookTableOfContext(bookId);

      generateBookCoverByAI(bookTitle);
    }
  };

  const handleBookAudienceInput = (value: string) => {
    setBookAudienceHasError(false);
    setBookAudience(value);
  };

  const handleBookStructureInput = (value: string) => {
    setBookStructureHasError(false);
    setBookStructure(value);
  };

  const onBookTitleEdited = async (value: string) => {
    if (value.trim().length === 0) {
      return;
    }
    const updateBookData: IBookData = await graphQlCall({
      queryTemplateObject: queries.UPDATE_BOOK_MUTATION,
      headerType: 'USER-AUTH',
      values: {
        id: bookData?._id,
        title: value,
      },
    });
    setBookData(updateBookData);
  };

  const onChapterTitleEdited = async (value: string, index: number) => {
    if (value.trim().length === 0) {
      return;
    }
    const updateChapterData: IBookChapter = await graphQlCall({
      queryTemplateObject: queries.UPDATE_CHAPTER_MUTATION,
      headerType: 'USER-AUTH',
      values: {
        bookId: bookData?._id,
        index: index,
        title: value,
      },
    });
    if (bookData) {
      const newBookData = { ...bookData };
      newBookData.chapters[index] = updateChapterData;
      setBookData(newBookData);
    }
  };

  const onChapterAdd = async (
    indexBefore: number,
    action: 'add' | 'insert'
  ) => {
    if (bookData) {
      setLoadingAddChapter(true);
      await addChapterBySocket(bookData?._id, action, indexBefore);
    }
  };

  const addChapterBySocket = async (
    bookId: string,
    action: string,
    indexBefore?: number
  ) => {
    if (!socket.current || !bookData) {
      return;
    }
    let payloadAction = action;
    if (indexBefore == bookData.chapters.length) {
      payloadAction = 'add';
    }
    const payload = {
      bookId,
      token: token,
      positionBefore: indexBefore,
      action: payloadAction,
    };

    const data: any = await getBySocket({
      emitEventName: 'ai-book-add-chapter',
      resultEventName: 'response-book-add-chapter',
      payload,
      socket: socket.current,
    });
    const newBookData = { ...bookData } as IBookData;

    const newChapterPositionForGql: number[] = [];
    const newChapters: IBookChapter[] = [];
    bookData.chapters.forEach((chapter: IBookChapter, index: number) => {
      if (indexBefore !== undefined && index === indexBefore) {
        newChapters.push({
          comments: [],
          text: '',
          title: extractChapterTitle(data.chapterTitle as string),
        });
      }
      newChapters.push(chapter);
    });

    if (payloadAction === 'add') {
      newChapters.push({
        comments: [],
        text: '',
        title: extractChapterTitle(data.chapterTitle as string),
      });
    }

    newBookData.chapters = newChapters;
    newBookData.chapters.forEach((el: any, index) => {
      newChapterPositionForGql.push(index);
    });
    setBookData(newBookData);
    chapterPositionForGql.current = newChapterPositionForGql;

    //////
    await graphQlCall({
      queryTemplateObject: queries.UPDATE_BOOK_MUTATION,
      headerType: 'USER-AUTH',
      values: {
        id: bookData?._id,
        chapters: JSON.stringify(newChapters)
      },
    });
    //////

    setLoadingAddChapter(false);
  };
  const deleteChapter = async (index: number) => {
    const deleteChapterData = await graphQlCall({
      queryTemplateObject: queries.DELETE_CHAPTER_MUTATION,
      headerType: 'USER-AUTH',
      values: {
        bookId: bookData?._id,
        index: index,
      },
    });
    if (deleteChapterData.message === 'chapter was deleted') {
      rxChapterIndexForDelete.next(index as any);
      if (bookData) {
        const newBookData = { ...bookData };
        const newChapters = [...newBookData.chapters];
        newChapters.splice(index, 1);
        newBookData.chapters = newChapters;

        setBookData(newBookData);

        const newChapterPositionForGql: number[] = [];
        newBookData.chapters.forEach((el: any, index) => {
          newChapterPositionForGql.push(index);
        });
        chapterPositionForGql.current = newChapterPositionForGql;
      }
    }
  };

  const reorderChapter = (dragIndex: number, dropIndex: number) => {
    if (bookData) {
      const newBookData = { ...bookData };
      let newChapters = [...newBookData.chapters];
      const tempChapter = newChapters.splice(dragIndex, 1);
      newChapters.splice(dropIndex, 0, tempChapter[0]);
      newBookData.chapters = newChapters;
      setBookData(newBookData);

      const newChapterPositionForGql = [...chapterPositionForGql.current];
      const tempChapterIndex = newChapterPositionForGql.splice(dragIndex, 1);
      newChapterPositionForGql.splice(dropIndex, 0, tempChapterIndex[0]);
      chapterPositionForGql.current = newChapterPositionForGql;
    }
  };

  const endReorderChapter = async () => {
    const reorderChaptersData = await graphQlCall({
      queryTemplateObject: queries.REORDER_CHAPTERS_MUTATION,
      headerType: 'USER-AUTH',
      values: {
        bookId: bookData?._id,
        indexes: chapterPositionForGql.current,
      },
    });
    if (bookData) {
      //TODO: DELETE THIS ONE I ASSUME SINCE THIS IS NOT IN EDITOR 
      rxNewChapterIndex.next(chapterPositionForGql.current as any);
      const newBookData = { ...bookData };
      newBookData.chapters = reorderChaptersData;
      setBookData(newBookData);
      const newChapterPositionForGql = newBookData.chapters.map(
        (el: any, index: number) => {
          return index;
        }
      );
      chapterPositionForGql.current = newChapterPositionForGql;
    }
  };

  const onNoteAdd = async (chapterIndex: number, noteText: string) => {
    const updateChapterData: IBookChapter = await graphQlCall({
      queryTemplateObject: queries.UPDATE_CHAPTER_MUTATION,
      headerType: 'USER-AUTH',
      values: {
        bookId: bookData?._id,
        index: chapterIndex,
        comments: [noteText],
      },
    });
    if (bookData) {
      const newBookData = { ...bookData };
      newBookData.chapters[chapterIndex] = updateChapterData;
      setBookData(newBookData);
    }
  };

  const handleGenerateBook = () => {
    if (selectBookImg) {
      rxSelectBookImg.next(selectBookImg);
      graphQlCall({
        queryTemplateObject: queries.SET_COVER_IMAGE_MUTATION,
        headerType: 'USER-AUTH',
        values: {
          bookId: bookData?._id,
          coverImageUrl: selectBookImg,
        },
      });
    }
    socket.current?.emit('ai-book-all-chapters', {
      chapters: bookData?.chapters,
      bookId: bookData?._id,
    })
    history.push(`/edit/book-editor/${bookData?._id}`);
  };

  useEffect(() => {
    fetchSectionsMenu();
  }, []);

  const redirectToBookleMenu = () => {
    window.open(`${CONSULT_URL}/console/books`, '_self');
  };

  if (step === 1) {
    return (
      <div className={clsx(s.bookPageContent,s.bookPageContentExtended)}>
        <div className={s.bookPageLogoBlock}>
          <span className={s.backButton} onClick={()=> redirectToBookleMenu()}><ArrowLeft/>Exit</span>
          <Logo/>
          <h3>Create a book in 30 seconds with our artificial intelligence</h3>
        </div>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <div className={s.inputsBlock}>
            <h2 className={s.bookPageHeader}>{'What is this book about?'}</h2>
            <Input  
              value={bookTitle}
              label="Title"  
              onChange={(value) => handleNameInput(value)}
              hasErrors={titleHasError}
              autoComplete='off'
              maxLength={150}
            />

            <Select
              label="Tone"
              onChange={(value) => handleToneInput(value)}
              value={tone}
              options={aviableTones}
              hasErrors={toneHasError}
            />
            {customToneEnabled && <Input  
              value={customTone}
              label="Custom Tone" 
              onChange={(value) => handleCustomToneInput(value)}
              hasErrors={customToneHasError}
              autoComplete='off'
              maxLength={150}
            />}
            <TextArea
              value={bookDescription}
              label="Book Description"
              onChange={(value) => handleDescriptionInput(value)}
              hasErrors={descriptionHasError}
              autoComplete='off'
              maxLength={5000}
            />
            <div className={s.buttonBlock}>
              <Button
                size="regular"
                onClick={() => handleNext()}
                borderRadius={'25px'}
              >
                Next
              </Button>
            </div>
          </div>
        )}
        <InappropriateAIContentPopup />
      </div>
    );
  } else if (step === 2) {
    return (
      <div className={s.bookPageContent}>
        <h2 className={s.bookPageHeader}>{'Audience and Structure'}</h2>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <div>
            <div className={s.inputsBlock}>
              <Input
                value={bookAudience}
                label="Who is your audience? (Optional)"
                onChange={(value) => handleBookAudienceInput(value)}
                hasErrors={bookAudienceHasError}
                autoComplete='off'
                maxLength={1000}
              />
              <div className={s.textAreaWrapper}>
                <TextArea
                  value={bookStructure}
                  label="Structure (Optional)"
                  onChange={(value) => handleBookStructureInput(value)}
                  hasErrors={bookStructureHasError}
                  autoComplete='off'
                  maxLength={5000}
                />
              </div>
              <div className={s.buttonsBlock}>
              <Button
                  size="regular"
                  color="secondary"
                  onClick={() => setStep(1)}
                  borderRadius={'25px'}
                >
                 Back
                </Button>
                <Button
                  size="regular"
                  onClick={() => handleNext()}
                  borderRadius={'25px'}
                >
                  Next
                </Button>
              </div>
            </div>
          </div>
        )}
        <InappropriateAIContentPopup />
      </div>
    );
  } else if (step === 3) {
    return (
      <div style={{ display: 'flex', height: '100%' }}>
        <span className={s.backButton} onClick={()=> setStep(2)}><ArrowLeft/>Back</span>
        <div className={s.bookPageGeneratorWrapper}>
          <div className={s.bookInfoBlock}>
            <div className={s.bookInfoBlockInner}>
              <BookInfoBlock
                bookTitle={bookData?.title ? bookData?.title : ''}
                onBookTitleEdited={onBookTitleEdited}
                chapters={bookData?.chapters ? bookData?.chapters : []}
                onChapterTitleEdited={onChapterTitleEdited}
                deleteChapter={deleteChapter}
                loadingChapter={loadingChapter}
                onChapterAdd={onChapterAdd}
                reorderChapter={reorderChapter}
                endReorderChapter={endReorderChapter}
                loadingAddChapter={loadingAddChapter}
                onNoteAdd={onNoteAdd}
                onGenerateBook={handleGenerateBook}
              />
            </div>
          </div>
          <div className={s.bookSelectImgBlockWrapper}>
            <div className={s.bookImgBlock}>
              <Slider
                slides={bookImg.map((img) => img)}
                loading={loadingBookImg}
                textForImg={bookData?.title}
                setCurrentImg={(img: string) => setSelectBookImg(img)}
              />
              {!loadingBookImg && (
                <div className={s.bookImgBlockText}>
                  Choose the Book Cover
                  <div>If needed, you will be able to replace it later</div>
                </div>
              )}
            </div>
          </div>
        </div>
        <InappropriateAIContentPopup />
      </div>
    );
  } else return null;
};

export default BookGenerator;
