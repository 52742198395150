import { LegacyRef, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { ReactComponent as MoreIcon } from 'Assets/More.svg';

import styles from './OverflowHandler.module.scss';

interface IProps {
  children: React.ReactNode[];
  parentContainerClass: string;
}

const OverflowHandler = ({ children, parentContainerClass }: IProps) => {
  const [showHiddenItem, setShowHiddenItems] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [hiddenItems, setHiddenItems] = useState<any[]>([]);

  const containerRef: LegacyRef<HTMLDivElement> = useRef<any>(null);
  const hiddenItemsContainerRef: LegacyRef<HTMLDivElement> = useRef<any>(null);
  const hiddenItemsRef = useRef(hiddenItems);

  const resizeHiddenItems = (isRecursiveCall = false) => {
    const hiddenItemsContainer = hiddenItemsContainerRef.current;
    if (!hiddenItemsContainer) return;

    const containers = document.getElementsByClassName(parentContainerClass);
    if (containers.length === 0) return;
    const parentContainer = containers[0];

    const adjustContainerPosition = () => {
      const hiddenItemsLeft = hiddenItemsContainer.getBoundingClientRect().left;
      const parentLeft = parentContainer.getBoundingClientRect().left;

      if (hiddenItemsLeft < parentLeft) {
        if (
          hiddenItemsContainer.clientWidth <=
          window.innerWidth - parentLeft
        ) {
          const adjustmentValue =
            hiddenItemsLeft < 0
              ? hiddenItemsLeft - parentLeft
              : hiddenItemsLeft -
                parentLeft +
                parseInt(hiddenItemsContainer.style.right, 10);
          hiddenItemsContainer.style.right = `${adjustmentValue}px`;
        } else if (hiddenItemsContainer.style.flexWrap !== 'wrap') {
          hiddenItemsContainer.style.flexWrap = 'wrap';
        } else {
          hiddenItemsContainer.style.right = `${-hiddenItemsContainer.clientWidth}px`;
        }
        setTimeout(resizeHiddenItems.bind(null, true), 0);
      }
    };

    if (!isRecursiveCall) {
      hiddenItemsContainer.style.right = '0px';
      hiddenItemsContainer.style.flexWrap = 'unset';
      setTimeout(resizeHiddenItems.bind(null, true), 0);
    } else {
      adjustContainerPosition();
    }
  };

  useEffect(() => {
    hiddenItemsRef.current = hiddenItems;
  }, [hiddenItems]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (showHiddenItem) resizeHiddenItems();
    }, 0);

    return () => clearTimeout(timeout);
  }, [showHiddenItem]);

  useEffect(() => {
    const adjustContent = () => {
      const containers = document.getElementsByClassName(
        parentContainerClass
      ) as HTMLCollectionOf<HTMLElement>;
      if (containers.length === 0 || !containers[0]) return;

      const container = containers[0];
      let containerWidth = container.clientWidth;

      const childNodes = Array.from(
        containerRef.current ? containerRef.current.children : []
      ) as HTMLElement[];

      const tempHiddenItems = [];
      for (let i = 0; i < children.length; i++) {
        if (!childNodes[i]) return;

        childNodes[i].style.display = '';
        containerWidth -= childNodes[i].offsetWidth;

        const computedStyle = window.getComputedStyle(childNodes[i]);
        if (computedStyle.margin)
          containerWidth -= parseInt(computedStyle.margin, 10);
        if (computedStyle.marginRight)
          containerWidth -= parseInt(computedStyle.marginRight, 10);
        if (computedStyle.marginLeft)
          containerWidth -= parseInt(computedStyle.marginLeft, 10);

        if (containerWidth - 30 <= 0) {
          childNodes[i].style.display = 'none';
          tempHiddenItems.push(children[i]);
        }
      }

      if (hiddenItemsRef.current.length !== tempHiddenItems.length) {
        setHiddenItems(tempHiddenItems);
        setIsOverflowing(tempHiddenItems.length > 0);
        if (tempHiddenItems.length === 0) setShowHiddenItems(false);
        setTimeout(() => {
          resizeHiddenItems();
        }, 0);
      }
    };

    setTimeout(() => {
      adjustContent(); // Initial adjustment
    }, 10);

    window.addEventListener('resize', adjustContent);

    return () => window.removeEventListener('resize', adjustContent);
  }, [children, parentContainerClass]);

  return (
    <>
      <div ref={containerRef} className={styles['overflow-handler']}>
        {children}
      </div>
      {isOverflowing && (
        <span
          className={clsx(styles.moreIcon, {
            [styles.showChevron]: showHiddenItem,
          })}
          onClick={() => setShowHiddenItems(!showHiddenItem)}
        >
          <MoreIcon />
          {showHiddenItem && (
            <div
              ref={hiddenItemsContainerRef}
              className={styles.hiddenItemsContainer}
              onClick={(e) => e.stopPropagation()}
            >
              {hiddenItems}
            </div>
          )}
        </span>
      )}
    </>
  );
};

export default OverflowHandler;
