import React, { useState, useEffect, useRef } from 'react';
import RerollInput from 'Components/AIRerollImage/RerollInput/RerollInput';
import RerollSelect from 'Components/AIRerollImage/RerollSelect/RerollSelect';
import RerollAiButton from './RerollAiButton/RerollAiButton';
import { generateAiImageBySocket } from 'utils/socket';
import { graphQlCall } from 'graphql/utils';
import queries from 'graphql/queries';
import { initiateBadWordsFilter } from 'Utils';
import { rxBadContentAlert } from 'rx/rxState';
import { getUserId } from 'utils/api';

interface IProps {
  x: number;
  y: number;
  onChange: (url: string) => void;
  onSubmit: (url: string) => void;
  onStart: () => void;
  onCancel: () => void; 
  immediatelyOpen?: boolean
}

const RerollComponent = (props: IProps) => {
  const { x, y} = props;
  const [inputOpen, setInputOpen] = useState(false);
  const [imagesUrls, setImagesUrls] = useState<string[]>([]);
  const [selectOpen, setSelectOpen] = useState(false);
  const [selectedUrl, setSelectedUrl] = useState('');
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(false);
  const [progressPercent, setProgressPercent] = useState(0);
  const [intervalF, setIntervalF] = useState(0);
  const [aiButtonOpen, setAiButtonOpen] = useState(true);
  const [imageConfirmed, setImageConfirmed] = useState(false);
  const [aiRequestId, setAiRequestId] = useState<null|string>(null);

  const percentRef = useRef(0);
  const aiUrlsRef = useRef<string[]>([]);
  const selectedRef = useRef<string | null>(null);
  const badWordFilter = initiateBadWordsFilter();

  useEffect(()=>{
    if (props.immediatelyOpen) {
      props.onStart();
      setAiButtonOpen(false);
      setInputOpen(true);
    }
  },[props.immediatelyOpen])

  const handleStartGeneration = async () => {
    if (!text || text.trim().length === 0) {
      return;
    }
    setLoading(true);
    imitateProgressBar();
    
    const imageUrls: string[] = await getNewImageUrlsWithAI(text);
    aiUrlsRef.current = imageUrls;
    setImagesUrls(imageUrls);
    setSelectedUrl(imageUrls[0]);
    setInputOpen(false);
    setSelectOpen(true);
    setLoading(false);

    props.onChange(imageUrls[0]);
  }

  const getNewImageUrlsWithAI = async (text: string): Promise<string[]> => {
    // const hasBadWords = badWordFilter.isProfane(text);
    // if (hasBadWords) {
    //   rxBadContentAlert.next(true);
    //   return [];
    // }

    const currentUrl = window.location.href;
    
    const ratio = currentUrl.includes('book-editor') ? '6:4' : '1:1';
    const payload: any = {
      text,
      imageCount: 4,
      ratio
    }

    const userId = getUserId();
    if (userId) {
      payload.userId = userId;
    }

    if (currentUrl.includes('/edit/edit/')) {
      const urlParts = currentUrl.split('/');
      const pageId = urlParts[urlParts.length - 2];
      payload.pageId = pageId;
    }

    console.log('TEST PAYLOAD', payload);

    const response: any = await generateAiImageBySocket({
      payload,
      callback: (response: any) => handleAiCallback(response.id, response.error),
    })

    return response.imageUrls as string[];
  }

  const handleAiCallback = (id: string, error?: string) => {
    if (error) {
      console.error('IMAGE GENERATION ERROR', error)
      setLoading(false);
      percentRef.current = 0;
      rxBadContentAlert.next(true);
    } else {
      setAiRequestId(id);
    }
  }

  const nextImage = () => {
    const currentIndex = imagesUrls.findIndex(url => url === selectedUrl);
    if (currentIndex === undefined || currentIndex === imagesUrls.length) {
      return;
    }
    imagesUrls.forEach((url, index) => {
      if (currentIndex + 1 === index) {
        setSelectedUrl(url);
        props.onChange(url);
      }
    })
  }

  const previousImage = () => {
    const currentIndex = imagesUrls.findIndex(url => url === selectedUrl);
    if (!currentIndex) {
      return;
    }
    imagesUrls.forEach((url, index) => {
      if (currentIndex - 1 === index) {
        setSelectedUrl(url);
        props.onChange(url);
      }
    })
  }

  const handleSubmit = () => {
    setSelectOpen(false);
    setAiButtonOpen(true);
    setImageConfirmed(true);
    props.onSubmit(selectedUrl);
    selectedRef.current = selectedUrl;
  }

  const handleEdit = () => {
    setSelectOpen(false);
    setInputOpen(true);
    setLoading(false);
  }

  const handleRegenerate = () => {
    setSelectOpen(false);
    setInputOpen(true);
    setLoading(true);
    handleStartGeneration()
  }

  const handleCancel = async () => {
    setLoading(false);
    percentRef.current = 0;
    if (aiRequestId) {
      await graphQlCall({
        queryTemplateObject: queries.CANCEL_IMAGE_GENERATION,
        headerType: 'USER-AUTH',
        values: {
          id: aiRequestId
        }
      });
    } else {
      console.log('NO AI REQUEST ID');
    }
  }

  useEffect(() => {
    percentRef.current += 1;
    setProgressPercent(percentRef.current);
  }, [intervalF]);

  useEffect(() => {
    return () => {
      const allUrls = [...aiUrlsRef.current];
      if (!allUrls.length) {
        return;
      }
      const unusedUrls = selectedRef.current ? allUrls.filter(url => url !== selectedRef.current) : allUrls;
      // removeUnusedFiles(unusedUrls);
    }
  }, []);

  const imitateProgressBar = () => {
    percentRef.current = 0;
    setIntervalF(0);
    const interval = setInterval(() => {
      if (percentRef.current < 100) {
        setIntervalF(percentRef.current + 1)
      } else {
        clearInterval(interval);
      }
    }, 200)
  }

  const handleAiButtonClicked = () => {
    props.onStart();
    setAiButtonOpen(false);
    setInputOpen(true);
  }

  const handleClose = () => {
    props.onCancel();
    setAiButtonOpen(true);
    setInputOpen(false)
  }

  const handleCancelSelect = () => {
    props.onCancel();
    setAiButtonOpen(true);
    setSelectOpen(false);
    setInputOpen(false);
  }

  const removeUnusedFiles = async (urls: string[]) => {
    await graphQlCall({
      queryTemplateObject: queries.REMOVE_FILES_FROM_S3,
      values: {
        urls
      }
    });
  }
  
  return (
    <div>
      {aiButtonOpen && <RerollAiButton x={x+560} y={y} onSubmit={() =>handleAiButtonClicked()}/>}
      { inputOpen && <RerollInput
        x={x}
        y={y}
        onClose={() => handleClose()}
        onSubmit={() => handleStartGeneration()}
        text={text}
        onTextChange={setText}
        loading={loading}
        onCancel={() => handleCancel()}
        percent={progressPercent}
      />}
      {selectOpen && <RerollSelect
        x={x+150}
        y={y}
        imageUrls={imagesUrls}
        onEdit={() => handleEdit()}
        onNext={() => nextImage()}
        onPrev={() => previousImage()}
        onRegenerate={() => handleRegenerate()}
        onSelect={() => handleSubmit()}
        selectedUrl={selectedUrl}
        onCancel={() => handleCancelSelect()}
      />}
    </div>
  )
}


export default RerollComponent;